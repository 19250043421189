<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Enquiry Audits</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0" id="enquiry_audit_heading">Enquiry Audits</h3>
            </div>
          </div>
          <div>
            <div class="col-12 text-right">
              <div class="row">
                <div class="col-5">
                  <base-input
                    v-model="searchQuery"
                    prepend-icon="fas fa-search"
                    placeholder="Press Enter to search..."
                    @keyup.enter="search"
                  ></base-input>
                </div>
                <div class="col-2">
                  <flat-pickr v-model="date" :config="dateConfig" ></flat-pickr>
                </div>
                <div class="col-5">
                  <el-select
                    class="mr-3"
                    v-model="timezone" size="mini" filterable
                    placeholder="Inspection timezone">
                    <el-option
                      v-for="timezone in allTimezones"
                      :key="timezone"
                      :label="timezone"
                      :value="timezone"
                    >
                    </el-option>
                  </el-select>
                  <el-switch v-model="failedOnly"></el-switch> <span class="text-sm">Failed only</span>
                </div>
              </div>
            </div>
            <el-table
              :data="EnquiryAudits"
              row-key="id"
              id="inspections_data_table"
              v-loading="loading"
              header-row-class-name="thead-light"
              @row-click="rowSelected"
            >
              <el-table-column label="Lead Name" prop="first_name" min-width="120px">
                <template v-slot="{ row }">
                  {{ row.first_name + ' ' +  row.last_name}}
                </template>
              </el-table-column>

              <el-table-column label="Event Type" prop="event_type" min-width="70px">
                <template v-slot="{ row }">
                  {{ row.event_type }}
                </template>
              </el-table-column> 
              
              <el-table-column label="Email" prop="email" min-width="180px">
                <template v-slot="{ row }">
                  <span class="font-weight-600 venue_name mb-0 text-sm">{{
                    row.email
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column label="Phone Number" prop="phone" min-width="180px">
                <template v-slot="{ row }">
                  <span class="font-weight-600 venue_name mb-0 text-sm">{{
                    row.phone
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Status"
                prop="status"
              >
                <template v-slot="{ row }">
                  <badge
                    rounded
                    :type="row.response_status == 'OK' ? 'success' : 'danger'"
                  >
                    {{ row.response_status }}
                  </badge>
                </template>
              </el-table-column>

              <el-table-column
                label="Lead Status"
                prop="status"
                min-width="70px"
              >
                <template v-slot="{ row }">
                  <badge
                    rounded
                    :type="row.lead_exists ? 'success' : 'danger'"
                  >
                    {{ row.lead_exists ? 'Created' : 'Failed' }}
                  </badge>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div>
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </base-pagination>
          </div>
        </card>
        <el-dialog
          title="Change or Update status."
          width="800px"
          v-loading="loading"
          :visible.sync="showEnquiryData"
          v-if="showEnquiryData"
        >
          <ShowEnquiryRawData :enquiryData="enquiryData" @close="showEnquiryData = false"/>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { 
  Table,
  TableColumn,
  Switch,
  Select,
  Option,
  Dialog
} from "element-ui";
import moment from 'moment';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { BasePagination } from "@/components";
import tableMixin from '@/helpers/remoteTableMixin';
import { EnquiryAuditService } from "@/services";
import ShowEnquiryRawData from "@/views/Widgets/Reports/ShowEnquiryRawData.vue";

export default {
  mixins: [tableMixin],
  components: {
    flatPickr,
    BasePagination,
    RouteBreadCrumb,
    ShowEnquiryRawData,
    [Option.name] : Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Switch.name]: Switch,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      EnquiryAudits: [],
      loading: true,
      failedOnly: false,
      date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      timezone: 'Australia/Sydney',
      showEnquiryData: false,
      enquiryData: {},
      dateConfig: {
        altInput: true,
        dateFormat: "YYYY-MM-DD",
        altFormat: "DD-MM-YYYY",
        allowInput: true,
        maxDate: moment().subtract(1, 'days').toDate(),
        parseDate: (datestr, format) => {
          return moment(datestr, format, true).toDate();
        },
        formatDate: (date, format, locale) => {
          // locale can also be used
          return moment(date).format(format);
        },
      },
      
    };
  },
  mounted() {
    this.loadPage();
  },
  computed: {
    allTimezones() {
      return [
        'Australia/Melbourne',
        'Australia/Sydney',
        'Australia/Brisbane',
        'Europe/London',
      ];
    },
  },
  methods: {
    async loadPage(page, search) {
      this.loading = true;
      let EnquiryAuditData = await EnquiryAuditService.paginate(page, search, this.date, this.timezone, this.failedOnly);
      this.EnquiryAudits = EnquiryAuditData.data;
      this.pagination.total = parseInt(EnquiryAuditData.meta.total);
      this.pagination.perPage = parseInt(EnquiryAuditData.meta.per_page);
      this.loading = false;
    },

    rowSelected(row) {
      this.enquiryData = row;
      this.showEnquiryData = true;
    },
  },
  watch: {
    async failedOnly () {
      this.loading = true;
      this.searchQuery = '';
      await this.loadPage();
      this.loading = false;
    },

    async date () {
      this.loading = true;
      await this.loadPage();
      this.loading = false;
    }, 
    
    async timezone () {
      this.loading = true;
      await this.loadPage();
      this.loading = false;
    },
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
div.el-table .el-table__row.el-table__row--level-1 {
  background: #5a74ff12;
}
div.el-table .el-table__row.el-table__row--level-1:hover {
  background: #5a74ff0a;
}
</style>