<template>
  <div class="row">
    <div class="col-lg-12">
      <div>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-card >
              <div slot="header" class="clearfix">
                <span>{{ enquiryData.first_name }} {{ enquiryData.last_name }} - {{ enquiryData.event_type }}</span>
              </div>
              <el-collapse v-model="active">
                <el-collapse-item name="payload">
                  <template slot="title">
                    <h4>Payload</h4>
                  </template>
                  <el-table :data="parseJson(enquiryData.payload)" style="width: 100%">
                    <el-table-column prop="key" width="180"></el-table-column>
                    <el-table-column prop="value">
                      <template slot-scope="{row, $index}">
                        <span v-if="! isResponseOk">
                          <el-input
                            v-model="row.value"
                            @blur="handleSave($index, row)"
                            size="small"
                            class="edit-input">
                          </el-input>
                        </span>
                        <span v-else>{{ row.value }}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-collapse-item>
                <el-collapse-item name="response">
                  <template slot="title">
                    <h4>Response</h4>
                  </template>
                  <el-table :data="parseJson(enquiryData.response)" style="width: 100%">
                    <el-table-column prop="key" width="180"></el-table-column>
                    <el-table-column prop="value" ></el-table-column>
                  </el-table>
                </el-collapse-item>
              </el-collapse>
            </el-card>
          </el-col>
        </el-row>
        <div class="text-right mt-3" v-if="! isResponseOk">
          <el-button type="primary" @click="reSubmit" v-loading="loading" :disabled="loading">Re-Submit</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EnquiryAuditService } from "@/services";

import { 
  Table,
  TableColumn,
  Collapse,
  CollapseItem,
  Select,
  Option,
  Row,
  Col,
  Card,
  Input,
  Button
} from "element-ui";

export default {
  components: {
    [Option.name] : Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Card.name]: Card,
    [Row.name]: Row,
    [Col.name]: Col,
    [Input.name]: Input,
    [Button.name]: Button,
  },
  props: {
    enquiryData: {
      required: true,
    },
  },
  data() {
    return {
      active: "payload",
      parsedPayload: this.parseJson(this.enquiryData.payload),
      loading: false,
    };
  },
  computed: {
    isResponseOk() {
      return this.enquiryData.response_status === 'OK'; 
    },
  },
  methods: {
    parseJson(jsonString) {
      let parsed = jsonString;
      if (typeof jsonString === 'string') {
        parsed = JSON.parse(jsonString);
      }
      
      return Object.keys(parsed).map(key => ({ key, value: parsed[key] }));
    },

    handleSave(index, row) {
      // Update local data.
      this.parsedPayload[index] = row;
    },

    async reSubmit() {
      this.loading = true;
      let data = this.parsedPayload.reduce((acc, item) => {
        acc[item.key] = item.value; 
        return acc;
      });

      await EnquiryAuditService.resendEnquiryData(data);
      this.loading = false;

      this.$emit('close');
    },
  },
};
</script>
